.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-react .video-react-big-play-button {
  line-height: 2;
  height: 60px;
  width: 60px;
  display: block;
  position: absolute;
  top: 40%;
  left: 40%;
  padding: 0;
  cursor: pointer;
  opacity: 0.33;
  border: 0;
  background-color: #fff !important;
  border-radius: 50%;

  &:before {
    color: #000;
  }
}


.video-react .video-react-big-play-button.video-react-big-play-button-center {
  margin-top: -30px;
  margin-left: -30px;
  top: 50% !important;
  left: 50% !important;
}

 .video-react .video-react-big-play-button.big-play-button-hide {
  display: none !important;
}
